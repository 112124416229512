import RealEst from "../assets/realState1.jpg";
import premierbnk from "../assets/premierbnk.JPG";
import dahabshil from "../assets/dahabshil9111.JPG";
import postie from "../assets/postiePort.JPG";
import mern11 from "../assets/mern444.JPG";
import Chat from "../assets/ymNw77.JPG";
import Nike from "../assets/Nike11.JPG";
import inventory11 from "../assetImages2/Inventory11.JPG";

import inventor22 from "../assetImages2/inventor22.JPG";

export const data = [
  {
    id: 1,
    name: "Inventory Management System",
    image: inventory11,
    github:
      "https://github.com/ymc4766/AI-Facemash-Model/blob/master/src/screens/HomeScreen.js",
    live: "https://inventory-u6jp.onrender.com/",
  },
  {
    id: 23,
    name: "Udemy Course",
    image: inventor22,
    github:
      "https://www.udemy.com/course/mern-stack-inventory-management-system-2024-2025/learn/?couponCode=I_YUSUFYMC420",
    live: "https://www.udemy.com/course/mern-stack-inventory-management-system-2024-2025/learn/?couponCode=I_YUSUFYMC420",
  },
  {
    id: 1,
    name: "React + Tailwing Css Application",
    image: premierbnk,
    github: "https://github.com/ymc4766/premier-bank-",
    live: "https://premierbank-clone.vercel.app/",
  },
  {
    id: 907,
    name: "React + Redux ToolKit Application",
    image: Nike,
    github: "https://github.com/ymc4766/Nike--web-app",
    live: "https://nikie-app.vercel.app/",
  },
  {
    id: 901,
    name: "Mern + Socket-io App",
    image: Chat,
    github: "https://github.com/ymc4766/chatie-app",
    live: "https://github.com/ymc4766/chatie-app",
  },

  // {
  //   id: 2,
  //   name: "React JS Application",
  //   image: RealEst,
  //   github: "https://github.com/ymc4766",
  //   live: "https://github.com/ymc4766",
  // },
  {
    id: 3,
    name: "React + Tailwing Css Application",
    image: dahabshil,
    github: "https://github.com/ymc4766/dahabshil-clone",
    live: "https://dahabshil-clone.netlify.app",
  },
  {
    id: 4,
    name: "React JS Application",
    image: postie,
    github: "https://github.com/ymc4766/postie-front-end",
    live: "https://github.com/ymc4766/",
  },
  {
    id: 5,
    name: "Mern Stack App",
    image: mern11,
    github: "https://github.com/ymc4766/shopie-mern-app",
    live: "https://github.com/ymc4766/shopie-mern-app/tree/master/front-end",
  },
];

export const hero = {
  title: "Its a Gig World Out",
  subtitle: "Then, Go Explore",
  text: "Digital strategy experts specializing website design, web development, and marketing.",
  //   btn1: "Get Started",
  //   btn2: "Get Demo",
  img: "https://images.pexels.com/photos/3379934/pexels-photo-3379934.jpeg?auto=compress&cs=tinysrgb&w=600",
};

export const memory = {
  title: "Sweet Memories",
  subtitle: "Come To Life Again",
  text: "What we have always taken care for 10 years is always prioritizing the comfort of our users. So, do not our quality.",
  img: dahabshil,
  experience: [
    { number: "10", title: "Year Experience" },
    { number: "400", title: "Year Collaboration" },
    { number: "30k+", title: "Happy Customer" },
  ],
};
