import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { motion } from "framer-motion"; // Import Framer Motion for animations

const Home = () => {
  const scrollDown = () => {
    const el = document.querySelector("#work");
    window.scrollTo({
      top: el?.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      name="home"
      className="w-full h-screen bg-gradient-to-r from-[#0a192f] to-[#161b29] text-white"
    >
      {/* Container */}
      <div className="max-w-[1200px] mx-auto px-8 flex flex-col justify-center h-full space-y-6">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          <p className="text-pink-600 text-lg sm:text-xl">Hi, my name is</p>
        </motion.div>

        <motion.h1
          className="text-5xl sm:text-8xl font-extrabold text-[#ccd6f6]"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.1 }}
        >
          Yusuf Mohamud Ali
        </motion.h1>

        <motion.h2
          className="text-3xl sm:text-6xl font-bold text-[#8892b0]"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.2 }}
        >
          Full Stack Developer
        </motion.h2>

        <motion.p
          className="text-[#8892b0] text-lg sm:text-2xl py-4 max-w-[700px] leading-relaxed"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.3 }}
        >
          I’m a full-stack software developer And Udemy Instructor specializing
          in building exceptional web applications with beautiful UI/UX
          experiences. Currently focused on building progressive full-stack
          applications, I’m passionate about integrating AI technologies into
          web development.
        </motion.p>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7, delay: 0.4 }}
        >
          <button
            onClick={scrollDown}
            className="text-white group border-2 px-6 py-3 my-2 flex items-center rounded-md hover:bg-pink-600 hover:border-pink-600 transition-all duration-300 ease-in-out"
          >
            Explore More
            <span className="group-hover:rotate-90 duration-300">
              <HiArrowNarrowRight className="ml-3" />
            </span>
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default Home;
